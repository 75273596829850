
export const serviceData=[{
    id:1,
    title:'BABY PORTRAITS ',
    text:"Our baby portrait photography sessions are designed to capture your little one's early moments in a natural and comfortable setting. We understand that babies require special attention and care, which is why our team of experienced photographers is skilled in handling newborns and infants to create the perfect shots.",
},
{
    id:2,
    title:'FAMILY PORTRAITS',
    text:"Our family portrait photography sessions are perfect for capturing the love and bond between you and your family. We understand that family dynamics are unique, which is why we work with you to create a comfortable and relaxed atmosphere that brings out the best in everyone",

},
{
    id:3,
    title:'MATERNITY PHOTOSHOOT',
    text:"Our maternity photography sessions are designed to celebrate the beauty and strength of mothers-to-be. We understand that pregnancy is a magical and transformative experience, and we'd love to capture this special moment in your life.",

},
]