import React, { useState } from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import image1 from "../../assets/gallery/1.jpeg";
import image2 from "../../assets/gallery/2.jpeg";
import image3 from "../../assets/gallery/3.jpeg";
import image4 from "../../assets/gallery/4.jpeg";
import image5 from "../../assets/gallery/5.jpeg";
import image6 from "../../assets/gallery/6.jpeg";
import image7 from "../../assets/gallery/7.jpeg";
import image8 from "../../assets/gallery/8.jpeg";
import image9 from "../../assets/gallery/9.jpeg";
import image10 from "../../assets/gallery/10.jpeg";
import image11 from "../../assets/gallery/11.jpeg";
import image12 from "../../assets/gallery/12.jpeg";
import image13 from "../../assets/gallery/13.jpeg";
import './gallery.css'
// import {images} from '../gallery/images'

const images=[image1,image4,image9,image2,image3,image6,image7,image8,image5,image10,image11]

const ReactImageGallery = () => {
    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)
  
    const handleOpenModal = (index) => {
      setSlideNumber(index)
      setOpenModal(true)
    }
  
    // Close Modal
    const handleCloseModal = () => {
      setOpenModal(false)
    }
  
    // Previous Image
    const prevSlide = () => {
      slideNumber === 0 
      ? setSlideNumber( images.length -1 ) 
      : setSlideNumber( slideNumber - 1 )
    }
  
    // Next Image  
    const nextSlide = () => {
      slideNumber + 1 === images.length 
      ? setSlideNumber(0) 
      : setSlideNumber(slideNumber + 1)
    }
  return (
    <div className='gallery section' id='gallery'>
    <div className='section-heading gallery-background'>
        Gallery
    </div>
    <div className='gallery-container'>
{openModal && 
        <div className='sliderWrap'>
          <i className="fa-solid fa-circle-xmark btnClose" onClick={handleCloseModal} ></i>
         <i className="fa-solid fa-circle-chevron-left btnPrev" onClick={prevSlide}></i>
         <i class="fa-solid fa-circle-chevron-right btnNext"  onClick={nextSlide}></i>
          <div className='fullScreenImage'>
            <img src={images[slideNumber]} alt='' />
          </div>
        </div>
      }
    <ResponsiveMasonry
    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
>
    <Masonry gutter='20px'>
        {images.map((image, i) => (
            <img
                key={i}
                src={image}
                style={{width: "100%", display: "block"}}
                alt=""
                onClick={ () => handleOpenModal(i) }
            />
        ))}
    </Masonry>
</ResponsiveMasonry>
</div>
</ div>
  )
}

export default ReactImageGallery