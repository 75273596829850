import React, { useEffect, useState } from 'react'

import './about.css'
function About() {
  return (

             <div className='about section'  id='about'>
            <div className='section-heading about-background'>About Me</div>
            <div className='about-content'>
      <div className='about-info'>
    <p>My name is Ramya Reddy, and I'm a passionate photographer with a keen eye for detail and a love for capturing life's precious moments.</p>
    <p>&nbsp; &nbsp; I have always been fascinated by the power of photography to tell stories and evoke emotions. From the time I was a child, I would always be found behind the camera, capturing images of my friends and family, and the beauty of the world around me. As I grew older, my zeal for photography only grew stronger, and I began to pursue it as a passion along with my profession as a software engineer.</p>
    <p>&nbsp; &nbsp;Portrait photography is my specialty, and I have experience shooting a wide range of portraits, from baby and family portraits to maternity photoshoots. My goal as a photographer is to capture the atmosphere of the moment, and to create images that tell a story and evoke emotion.</p>
    <p>&nbsp; &nbsp; I approach each project with a personalized touch, taking the time to understand your unique vision and requirements. Whether you're looking for candid shots or posed portraits, I am committed to working closely with you to bring your ideas to life.</p>
    <p>&nbsp;&nbsp; Thank you for taking the time to visit my website. I invite you to explore my portfolio and to contact me to discuss your next event. Let's work together to capture memories that will last a lifetime!</p> 
     <p className='signature'>-Ramya Reddy</p>
      </div>
            </div>
        </div>

  )
}

export default About