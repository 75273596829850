import Navbar from './components/navbar/Navbar'
import Homepage from './components/homepage/Homepage';
import About from './components/about/About';
import Services from './components/services/Services';
import ReactImageGallery from './components/gallery/ReactImageGallery';
import Contact from './components/contact/Contact';
function App() {
  

  return (
    <div className="App">
      <Navbar />
      <Homepage/>
      <About/>
      <Services/> 
     <ReactImageGallery/>
     <Contact/>
    </div>
  );
}

export default App;
