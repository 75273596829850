import React from 'react'
import './homepage.css'
export default function Homepage() {

  return (
    
    <div className='homepage section'>
      <div className='content'>
       <div className='hero-content'>
       {/* <div className='sub-heading'>BIRTHDAYS, WEDDING & PARTIES</div> */}
       <div className='heading'><q>One Frame at a Time.</q></div>
       <div className='sub-heading-message'>Welcome to my photography portfolio! I am a passionate photographer with a focus on Portrait photography. Take a moment to explore my portfolio and see some of my best work.</div>
       <div className='buttons'>
       <a href='#gallery'><button class="button button--ujarak button--border-medium button--round-s button--text-thick" >See my Work</button></a>
       <a href='#contact'><button class="button button--ujarak button--border-medium button--round-s button--text-thick">Work With me</button></a>
       </div>
       </div>
      </div>
      </div>

  )
}
